import styled from 'react-emotion';

import { boxShadow, colors } from '../variables';

import { hexToRGB } from '../../util/style';

export const ModalAnimation = styled.div`
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	animation: fadeIn 500ms;
`;

export const ModalContainer = styled.div`
	align-items: center;
	background-color: rgba(${hexToRGB(colors.darkAccent)}, 0.85);
	display: flex;
	height: 100vh;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100vw;
	z-index: 2500;
`;

interface BoxProps {
	windowHeight: number;
	windowWidth: number;
}

export const ModalBox = styled.div`
	border-radius: 10px;
	box-shadow: ${boxShadow};
	height: auto;
	margin: auto;
	max-height: ${({ windowHeight }: BoxProps) => windowHeight - 80}px;
	max-width: ${({ windowWidth }: BoxProps) => windowWidth - 100}px;
	padding: 0;
	width: ${({ windowWidth }: BoxProps) => windowWidth * 0.8}px;
	z-index: 2501;
`;

export const ModalBody = styled.div`
	background-color: transparent;
	border-radius: 10px;
	height: 100%;
	margin: 0;
	padding: 0;
	width: 100%;
`;

export const ModalContent = styled.div`
	overflow: auto;
	width: 100%;
	max-height: 90vh;
	max-width: 90vw;
`;
