import Img, { FluidObject } from 'gatsby-image';
import { default as React, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'react-emotion';

import Page from '../components/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';
import Modal from '../components/Modal';
import PictureSection from '../components/PictureSection';

import { fluidImage as fluidImageFragment } from '../queries/fragments';

import bind from '../util/pureBind';
import { breakpoints } from '../styles/variables';
import { useContainerWidth } from '../util/hooks';

interface TravelProps {
	data?: any;
}

interface TravelState {
	modal: { dataPath: string; show: boolean; };
}

const TravelPics = styled.div`
	padding: 0 12px;
	justify-content: space-around;
`;

const handleClick = (modal: TravelState['modal'], setModalContents: (arg: TravelState['modal']) => null, fluid: FluidObject) => {
	const newModal = {
		...modal,
		fluid,
		show: !modal.show,
	};
	setModalContents(newModal);
};

interface WidthProps {
	containerWidth?: number;
	width?: number;
}

const setPictureWidth = (width?: number) => {
	const widthProp: WidthProps = {};
	if (width && (width < breakpoints.md)) {
		widthProp.containerWidth = width;
		widthProp.width = 0.9;
	}
	return widthProp;
};

const Travel: React.FunctionComponent<TravelProps> = props => {
	const [modal, setModalContents] = useState({ fluid: undefined, show: false, });
	const id = 'travelpics';
	const containerWidth = useContainerWidth(`#${id}`, props);
	const pictureSectionWidth = setPictureWidth(containerWidth);
	return (
		<IndexLayout>
			<Page>
				<Container>
					{
						modal.show ? (
							<Modal onClick={bind(handleClick, modal, setModalContents)}>
								<Img fluid={modal.fluid} />
							</Modal>
						) : (<div />)
					}
					<TravelPics id={id}>
						<PictureSection
							edges={props.data.south_africa.edges}
							onClick={bind(handleClick, modal, setModalContents)}
							subText="2018"
							text="South Africa and Lesotho"
							{...pictureSectionWidth}
						/>
						<PictureSection
							edges={props.data.peru.edges}
							onClick={bind(handleClick, modal, setModalContents)}
							subText="2017"
							text="Peru"
							{...pictureSectionWidth}
						/>
						<PictureSection
							edges={props.data.morocco.edges}
							onClick={bind(handleClick, modal, setModalContents)}
							subText="2016"
							text="Morocco"
							{...pictureSectionWidth}
						/>
						<PictureSection
							edges={props.data.nicaragua.edges}
							onClick={bind(handleClick, modal, setModalContents)}
							subText="2016"
							text="Nicaragua"
							{...pictureSectionWidth}
						/>
					</TravelPics>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export default Travel;

export const fluidImage = fluidImageFragment;
export const pageQuery = graphql`
	query {
		south_africa: allFile(
			filter: {
				ext: { regex: "/jpg|png/" }
				relativePath: { regex: "/travel.*South_Africa/" }
			}
			sort: { fields: [relativePath], order: ASC }
		) {
			edges {
				node {
					...fluidImage
				}
			}
		}
		peru: allFile(
			filter: { ext: { regex: "/jpg|png/" }, relativePath: { regex: "/travel.*Peru/" } }
			sort: { fields: [relativePath], order: ASC }
		) {
			edges {
				node {
					...fluidImage
				}
			}
		}
		morocco: allFile(
			filter: { ext: { regex: "/jpg|png/" }, relativePath: { regex: "/travel.*Morocco/" } }
			sort: { fields: [relativePath], order: ASC }
		) {
			edges {
				node {
					...fluidImage
				}
			}
		}
		nicaragua: allFile(
			filter: { ext: { regex: "/jpg|png/" }, relativePath: { regex: "/travel.*Nicaragua/" } }
			sort: { fields: [relativePath], order: ASC }
		) {
			edges {
				node {
					...fluidImage
				}
			}
		}
	}
`;
