import * as React from 'react';

import {
	ModalAnimation,
	ModalBody,
	ModalBox,
	ModalContainer,
	ModalContent,
} from '../styles/components/_modal';

import { useWindowDimensions } from '../util/hooks';

interface ModalProps {
	children: React.ReactNode;
	onClick: () => void;
}

const Modal: React.FunctionComponent<ModalProps> = ({ children, onClick }) => {
	const { height, width } = useWindowDimensions();
	return (
		<ModalContainer onClick={onClick}>
			<ModalBox windowHeight={height} windowWidth={width}>
				<ModalAnimation>
					<ModalBody>
						<ModalContent>{children}</ModalContent>
					</ModalBody>
				</ModalAnimation>
			</ModalBox>
		</ModalContainer>
	);
};

export default Modal;
